import { ButtonLinkProps } from 'src/components/atoms/Link'

import { CtaButton, InternalLink } from 'src/config/generated/sanitySchema'

import { CallToActionButtonLink } from '../CallToActionButtonLink'

export interface Props extends Omit<CtaButton, '_type' | 'internalLink'> {
  internalLink: InternalLink | undefined
  color?: ButtonLinkProps['color']
  size?: ButtonLinkProps['size']
}

export const CmsCtaButton = ({
  text,
  externalUrl,
  internalLink,
  linkType,
  color = 'primary',
  size = 'base'
}: Props) => {
  const href = linkType === 'internal' ? internalLink?.path : externalUrl

  return (
    <CallToActionButtonLink
      isExternal={linkType === 'external'}
      color={color}
      href={href || ''}
      size={size}
    >
      {text}
    </CallToActionButtonLink>
  )
}
