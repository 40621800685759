import Head from 'next/head'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useMemo } from 'react'

import { Footer } from 'src/components/layouts/Footer'
import { Header } from 'src/components/layouts/Header'
import { Hero, HeroProps } from 'src/components/layouts/Hero'
import { Loader } from 'src/components/layouts/Loader'
import { ConsentBanner } from 'src/components/organisms/ConsentBanner'
import { Modal } from 'src/components/templates/Modal'

import { env } from 'src/env/client.mjs'
import { useMessenger } from 'src/hooks/useMessenger'
import { isAdmin } from 'src/utils/admin'

import { Banner } from '../Banner'
import { Breadcrumbs } from '../Breadcrumbs'

const DEFAULT_SEO_TITLE = 'Trees That Count'
const DEFAULT_SEO_DESCRIPTION =
  'We are helping Kiwis to plant more native trees. Help make an impact on climate change and improve our environment.'
const DEFAULT_SEO_IMAGE = `${env.NEXT_PUBLIC_ASSETS_DOMAIN}/assets/img/logos/logo-og-meta.png`

export enum PageBackgroundColor {
  cyan = 'cyan',
  linen = 'linen',
  olivine = 'olivine',
  white = 'white',
  aquaSpring = 'aquaSpring',
  alabaster = 'alabaster'
}

export type PageProps = {
  backgroundColor?: PageBackgroundColor
  children: ReactNode | ReactNode[]
  heroAction?: HeroProps['ctaButtons']
  heroImage?: HeroProps['image']
  heroSubtitle?: HeroProps['subtitle']
  heroTitle?: string
  image?: string
  isFullWidth?: boolean
  showBreadcrumbs?: boolean
  showHero?: boolean
  subtitleComponent?: HeroProps['subtitleComponent']
  title?: string
  isIntercomEnabled?: boolean
  heroComponent?: ReactNode
  metaTitle?: string
  metaDescription?: string
  verticalMargin?: 'base' | 'none'
}

export const Page = ({
  backgroundColor,
  children,
  heroAction,
  heroImage,
  heroSubtitle,
  heroTitle,
  image = DEFAULT_SEO_IMAGE,
  isFullWidth = false,
  verticalMargin = 'base',
  showBreadcrumbs = true,
  showHero,
  subtitleComponent,
  title = DEFAULT_SEO_TITLE,
  isIntercomEnabled = false,
  heroComponent,
  metaTitle = DEFAULT_SEO_TITLE,
  metaDescription = DEFAULT_SEO_DESCRIPTION
}: PageProps) => {
  const router = useRouter()

  const { boot, shutdown } = useMessenger()

  useEffect(() => {
    if (isIntercomEnabled) {
      boot()
    }
    return () => {
      shutdown()
    }
  }, [boot, isIntercomEnabled, shutdown])

  const buildPageBackgroundColorClasses = () => {
    switch (backgroundColor) {
      case PageBackgroundColor.cyan:
        return 'bg-cyan'
      case PageBackgroundColor.linen:
        return 'bg-linen'
      case PageBackgroundColor.olivine:
        return 'bg-olivine'
      case PageBackgroundColor.white:
        return 'bg-white'
      case PageBackgroundColor.aquaSpring:
        return 'bg-aquaSpring'
      case PageBackgroundColor.alabaster:
        return 'bg-alabaster'
      default:
        return 'bg-linen'
    }
  }

  const buildContainerClasses = () => {
    if (isFullWidth) {
      return 'text-shark h-full'
    }

    const baseClassNames = `py-4 mx-auto px-3 text-shark h-full`
    if (isAdmin(router.pathname)) return `wide-container ${baseClassNames}`

    return `container ${baseClassNames}`
  }

  const buildHeroClasses = () => {
    return showHero && heroImage ? 'pt-[200px] md:pt-0' : ''
  }

  const canonicalUrl = (
    env.NEXT_PUBLIC_APP_DOMAIN + (router.asPath === '/' ? '' : router.asPath)
  ).split('?')[0]

  const hero = useMemo(() => {
    if (showHero && !heroComponent) {
      return (
        <Hero
          title={heroTitle || title}
          subtitle={heroSubtitle}
          subtitleComponent={subtitleComponent}
          ctaButtons={heroAction}
          image={heroImage}
          showBreadcrumbs={showBreadcrumbs}
        />
      )
    }
    if (showHero && heroComponent) {
      return heroComponent
    }
    return null
  }, [
    showHero,
    heroComponent,
    heroTitle,
    title,
    heroSubtitle,
    subtitleComponent,
    heroAction,
    heroImage,
    showBreadcrumbs
  ])

  return (
    <>
      <Head key='pageHead'>
        <meta property='og:image' content={image} />
        <title>{`${title} | Trees That Count`}</title>
        <meta property='og:title' content={`${metaTitle} | Trees That Count`} />
        <meta property='og:url' content={canonicalUrl} />
        <meta property='og:description' content={metaDescription} />
        <meta property='fb:pages' content='684508498380306' />
        <meta name='description' content={metaDescription} />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='canonical' href={canonicalUrl} />
      </Head>
      <Banner />
      <Header />
      {hero}
      <main
        className={`w-full min-h-[80vh] relative ${
          !isFullWidth && verticalMargin === 'base' ? 'py-3' : 'py-0'
        } ${buildPageBackgroundColorClasses()}`}
      >
        <Loader>
          {showBreadcrumbs && !showHero ? <Breadcrumbs /> : null}
          <div className={`${buildHeroClasses()} ${buildContainerClasses()}`}>
            {children}
          </div>
        </Loader>
      </main>
      <Footer />
      <Modal />
      <ConsentBanner />
    </>
  )
}
