import { useEffect, useMemo, useState } from 'react'

import { useAuth } from 'src/providers/AuthProvider'
import { useUser } from 'src/providers/UserProvider'

import { DesktopNavigation } from 'src/components/layouts/Header/DesktopNavigation'
import { MobileNavigation } from 'src/components/layouts/Header/MobileNavigation'
import { MobileNavigationToggle } from 'src/components/layouts/Header/MobileNavigationToggle'

import { env } from 'src/env/client.mjs'
import useMediaQuery from 'src/hooks/useMediaQuery'
import { Stages } from 'src/types/stages'

import { buildAdminLinks, buildUserLinks, buildAdvisorLinks } from './helper'

export const Header = () => {
  const { isStaff, isAdvisor } = useAuth()
  const { user, isAdminMode, isLoading } = useUser()
  const isMobile = useMediaQuery('(max-width: 1024px)')
  const [isOpen, setIsOpen] = useState(false)
  const isProduction = env.NEXT_PUBLIC_APP_STAGE === Stages.PRODUCTION

  useEffect(() => {
    if (!isMobile) setIsOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  const links = useMemo(() => {
    if (isLoading) return []
    if (isStaff && isAdminMode) {
      return buildAdminLinks()
    }
    if (isAdvisor && isAdminMode) {
      return buildAdvisorLinks()
    }

    return buildUserLinks({ user })
  }, [isStaff, isAdminMode, user, isLoading])

  return (
    <header
      className='z-20 relative py-5 bg-white shadow-[0_15px_15px_0_rgba(0,0,0,.03)]'
      role='banner'
    >
      <nav
        id='main_nav'
        className='relative flex flex-row justify-between px-0 sm:flex-nowrap sm:justify-start md:justify-center'
      >
        <DesktopNavigation links={links} />
        <MobileNavigationToggle isOpen={isOpen} onSetIsOpen={setIsOpen} />

        {isProduction ? null : (
          <div className='absolute p-2 text-center w-40 -left-10 bg-[rgba(255,255,0,.8)] bg-clip-border -rotate-45 shadow-[0_2px_2px_0_rgba(200,200,0,.5)] capitalize'>
            {env.NEXT_PUBLIC_APP_STAGE}
          </div>
        )}
      </nav>
      {isOpen ? (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <div
          onClick={() => {
            setIsOpen(false)
          }}
          className='menu-overlay visible lg:invisible'
        >
          <MobileNavigation links={links} />
        </div>
      ) : null}
    </header>
  )
}
